import * as React from "react"
import { graphql } from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss";
import "../scss/grunticon.scss";
import SEO from "../components/seo";
import "../scss/custom.scss";
import GuideDetail from "../components/GuideDetail/GuideDetail";

const AreaGuideTemplate = (props) => {
     
    const GQLPage = props.data.glstrapi?.areaGuide;
    return (
        <div className="area-details-page">
            <SEO title={GQLPage.Meta_Title} description={GQLPage.Meta_Description} location={props.location}  />
            <GuideDetail
                data={GQLPage}
            />
        </div>
    )
}

export default AreaGuideTemplate;

export const pageQuery = graphql`
    query AreaGuideQuery($id: ID!) {
        glstrapi {
            areaGuide(id: $id, publicationState: LIVE) {
                id
                Title
                URL
                Area_Sub_Title
                Area_Title
                CTA_1_Label
                CTA_2_Label
                Embed_Video_URL
                AutoPlay
                Repeat
                Latitude
                Longitude
                Meta_Description
                Meta_Title
                CTA_1_Link {
                    id
                    URL
                }
                CTA_2_Link {
                    id
                    URL
                }
                Tile_Image {
                    alternativeText
                    url
                }
                Publish
                Speak_with_teams {
                    Email
                    Tile_Image {
                        url
                        alternativeText
                    }
                    Name
                    Phone
                    Designation
                    offices {
                        Name
                        Mobile
                        URL
                    }
                }
                Modules_Area_Guides {
                    ... on GLSTRAPI_ComponentModulesIntro {
                        id
                        CTA_Label
                        Intro_Content
                        Intro_Title
                        Sidebar
                        CTA_Link {
                            _id
                            URL
                        }
                    }
                    ... on GLSTRAPI_ComponentModulesContentWithMultipleImages {
                        id
                        Content
                        Title
                        Images {
                            url
                            alternativeText
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsAddServiceContent {
                        id
                        CTA_Label
                        Blk_Title: Title
                        Description
                        CTA_Link {
                            id
                            URL
                        }
                    }
                    ... on GLSTRAPI_ComponentModulesFooterBlock {
                        id
                        CTA_1_Label
                        CTA_2_Label
                        Title
                        CTA_1_URL {
                            URL
                            _id
                        }
                        CTA_2_URL {
                            _id
                            URL
                        }
                    }
                }
            }
        }
    }
`